import {useEffect, useId, useState} from "react";
import * as React from "react";
import {Avatar, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import { AddCircleOutlined } from "@mui/icons-material";
import {createTopic} from "../../modules/topics";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {uploadImage, clearBuffer} from "../../modules/images";
import Grid from "@mui/material/Grid";



const CreateTopic = () => {
  const dispatch = useDispatch();

  const { imageState } = useSelector((state) => state);

  const [topicName, setTopicName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [topicImageUrl, setTopicImageUrl] = useState();

  useEffect(() => {dispatch(clearBuffer())}, [])



  useEffect(() => {
    setTopicImageUrl(imageState.imageUrl);
  }, [imageState.loading])


  const validateAndSendRequest = () => {
    if (!topicName) {
      toast.warning("Topic name is required");
    } else if (!topicImageUrl) {
      toast.warning("Topic image is required");
    } else if (!topicDescription) {
      toast.warning("Topic description is required");
    } else {
      dispatch(createTopic({
        title: topicName,
        description: topicDescription,
        image_url: topicImageUrl}));
    }
  }

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      dispatch(uploadImage({
        bucket: "articles",
        folderName: "topics-drafts",
        imageBytes: reader.result,
      }));
      }
  };

  const labelId = useId();



  return (
    <Grid container columns={1} spacing={2}>
      <Grid item xs={1} >
            <input
              accept="image/*"
              style={{ visibility: "hidden" }}
              id={labelId}
              type="file"
              onChange={(e) => handleUploadClick(e)}
            />
      </Grid>
      <Grid item xs={1} >
            <div style={{width: "128px", height: "128px"}}>
              {topicImageUrl? <label htmlFor={labelId}><Avatar
                aria-label="user"
                src={topicImageUrl}
                sx={{ width: "128px", height: "128px" }}
              >
                {topicName}
              </Avatar></label> : <>
                <label htmlFor={labelId}>
                  <div style={{ width: "128px",
                    height: "128px",
                    background: "#e0e0e0",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }} className={"MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiIconButton-sizeMedium css-122mdbe-MuiButtonBase-root-MuiIconButton-root"}>
                    <AddCircleOutlined />
                  </div>
                </label>
              </>}
          </div>
      </Grid>
      <Grid item xs={1} >
            <TextField
              fullWidth
              label="Topic name"
              id="fullWidth"
              variant="standard"
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
            />
      </Grid>
      <Grid item xs={1} >
            <TextField
              fullWidth
              label="Description"
              id="fullWidth"
              variant="standard"
              multiline
              value={topicDescription}
              onChange={(e) => setTopicDescription(e.target.value)}
            />
            <Typography variant="caption" display="block" gutterBottom>
              Please write a short description of the topic
            </Typography>
      </Grid>
      <Grid item xs={1} >
        <Button fullWidth={true} variant="outlined" color="success" onClick={() => validateAndSendRequest()}>
          Create Topic
        </Button>
      </Grid>
    </Grid>
  )
}

export default CreateTopic;
