

const OPEN_FORM = 'OPEN_FORM';
const CLOSE_FORM = 'CLOSE_FORM';

const initialState = {
  formData: [],
  opened: false,
  loading: false,
  error: null,
  content: null,
  title: null,
  description: null
};

const formReducer = (state = initialState, {type, payload}) => {
  console.log('formReducer', type, payload)
  switch (type) {

    case OPEN_FORM:
      return {
        ...state,
        opened: true,
        title: payload.title,
        description: payload.description,
        content: payload.content
      };

    case CLOSE_FORM:
      return {
        ...state,
        opened: false,
        content: null,
        title: null,
        description: null
      };

    default:
      return state;
  }
};

export default formReducer;

export const openForm = (payload) => {
  return {
    type: OPEN_FORM,
    payload: payload
  }
};
export const closeForm = (payload) => {
  return {
    type: CLOSE_FORM,
    payload: payload
  }
};
