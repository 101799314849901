import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import Modal from "react-overlays/Modal";
import {closeForm} from "../../modules/form";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const ScreenModal = styled(Modal)`
  position: fixed;
  width: fit-content;
  height: 100vh;
  z-index: 1240;
  top: 0;
  right: 0;
`;
function FrameModal() {
  const dispatch = useDispatch();
  const { formState } = useSelector((state) => state);
  const renderBackdrop = (props) => <Backdrop {...props} />;


  return (
    <>
      <ScreenModal
        show={formState.opened}
        onHide={() => dispatch(closeForm())}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
      >
        <Paper style={{height: "100%", padding: "20px", paddingBottom: "20px", overflowY: "scroll"}}>
          <IconButton color="primary" component="label" onClick={() => dispatch(closeForm())} style={{marginBottom: "10px"}}>
            <CloseOutlinedIcon />
          </IconButton>
          <Typography variant={"h4"}>{formState.title}</Typography>
          <Typography variant={"h5"}>{formState.description}</Typography>
          <Divider style={{marginTop: "15px", marginBottom: "15px"}} />
          <div>
            {formState.content}
          </div>
        </Paper>
      </ScreenModal>
    </>
  );
}


export default FrameModal;
