import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import {getHostUrl, ARTICLES_SERVICE} from "./environments";

const GET_ARTICLES = 'GET_ARTICLES';

const articlesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ARTICLES + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export default articlesReducer;

export const getArticles = (userId) => {
  return {
    type: GET_ARTICLES,
      payload: {
    request: {
      url: `${getHostUrl(ARTICLES_SERVICE)}/users/${userId}/articles`,
    },
  },
  }
};
