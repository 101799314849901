import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import {getArticles} from "../../modules/articles";
import Article from "../articles/article";



function Articles({userId}) {
  const dispatch = useDispatch();

  const { articles } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getArticles(userId))
  }, []);

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        height: 'fit-content',
        justifyContent: 'space-around'
      }}>
        {
          Array.isArray(articles) && articles.length? <></>: <Paper style={{
            padding: "15px",
            margin: "5px",
            width: '100%',
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}><Typography variant={"h4"}>
            No articles published
          </Typography></Paper>
        }
        {articles.map((article) => (
          <Article
            title={article.title}
            summary={article.summary}
            image_url={article.image_url}
            data={article.data}
            tags={article.tags}
            comments={article.comments}
            views={article.views}
          />
        ))}
        {articles.map((article) => (
          <Article
            title={article.title}
            summary={article.summary}
            image_url={article.image_url}
            data={article.data}
            tags={article.tags}
            comments={article.comments}
            views={article.views}
          />
        ))}
        {articles.map((article) => (
          <Article
            title={article.title}
            summary={article.summary}
            image_url={article.image_url}
            data={article.data}
            tags={article.tags}
            comments={article.comments}
            views={article.views}
          />
        ))}
      </div>
    </>
  );
}

Articles.propTypes = {
  userId: PropTypes.string.isRequired
};

export default Articles;
