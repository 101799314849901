import {getHostUrl, MAIN_API_SERVICE } from "./environments";
import {FAILURE_SUFFIX, REQUEST_SUFFIX, SUCCESS_SUFFIX } from "./types";
import HttpService from "../services/HttpService";
import {toast} from "react-toastify";

const GET_TIMELINES = 'GET_TIMELINES';
const PATCH_TIMELINE = 'PATCH_TIMELINE';
const CREATE_TIMELINE = 'CREATE_TIMELINE';
const DELETE_TIMELINE = 'DELETE_TIMELINE';

const initialState = {
  timelines: [],
  loading: false,
  error: null,
  timelineIsCreating: false
};
const timelineReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case DELETE_TIMELINE + REQUEST_SUFFIX:
      return {
        ...state,
        timelineIsCreating: true
      }
    case DELETE_TIMELINE + SUCCESS_SUFFIX:
      toast.info("Timeline was deleted");
      return {
        ...state,
        timelines: state.timelines.filter(timeline => timeline.id !== payload.data.id),
        timelineIsCreating: false
      }

    case CREATE_TIMELINE + REQUEST_SUFFIX:
      return {
        ...state,
        timelineIsCreating: true
      }

    case CREATE_TIMELINE + SUCCESS_SUFFIX:
      toast.info("Timeline was created");
      return {
        ...state,
        timelines: [payload.data, ...state.timelines],
        timelineIsCreating: false
      }

    case CREATE_TIMELINE + FAILURE_SUFFIX:
      return {
        ...state,
        timelineIsCreating: false
      }

    case PATCH_TIMELINE + REQUEST_SUFFIX:
      return {
        ...state
      }

    case PATCH_TIMELINE + SUCCESS_SUFFIX:
      return {
        ...state,
        timelines: state.timelines.map(timeline => {
          if (timeline.id === timeline.payload.data.id) {
            return timeline.payload.data;
          }
          return timeline;
        })
      }

    case GET_TIMELINES + REQUEST_SUFFIX:
      return {
        ...state,
        timelines: [],
        loading: true,
      };

    case GET_TIMELINES + SUCCESS_SUFFIX:
      return {
        ...state,
        timelines: payload.data,
        loading: false,
      };

    default:
      return state;
  }
};

export default timelineReducer;

export const getTimelines = (topicId) => {
  return {
    types: [GET_TIMELINES + REQUEST_SUFFIX, GET_TIMELINES + SUCCESS_SUFFIX, GET_TIMELINES + FAILURE_SUFFIX],
      payload: {
    request: {
      url: `${getHostUrl(MAIN_API_SERVICE)}/timelines`,
      params: {
        topic_id: topicId,
        limit: 1000,
        sort: 'date'
      }
    },
  },
  }
};

export const publishTimeline = ({id, publishState}) => {
  const request_method = publishState ?  HttpService.HttpMethods.PUT : HttpService.HttpMethods.DELETE;

  return {
    types: [PATCH_TIMELINE + REQUEST_SUFFIX, PATCH_TIMELINE + SUCCESS_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(MAIN_API_SERVICE)}/timelines/${id}/publish`,
        method: request_method
      },
    },
  }
};

export const patchTimeline = ({id, title, text, date, date_accuracy}) => {


  return {
    types: [PATCH_TIMELINE + REQUEST_SUFFIX, PATCH_TIMELINE + SUCCESS_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(MAIN_API_SERVICE)}/timelines`,
        method: HttpService.HttpMethods.PATCH,
        data: {
          id: id,
          title: title,
          text: text,
          date: date,
          date_accuracy: date_accuracy
        }
      },
    },
  }
};

export const deleteTimeline = (id) => {
  return {
    types: [DELETE_TIMELINE + REQUEST_SUFFIX, DELETE_TIMELINE + SUCCESS_SUFFIX, DELETE_TIMELINE + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(MAIN_API_SERVICE)}/timelines/${id}`,
        method: HttpService.HttpMethods.DELETE
      },
    },
  }
}

export const saveTimeline = ({title, text, date, date_accuracy, topic_id}) => {

  return {
    types: [CREATE_TIMELINE + REQUEST_SUFFIX, CREATE_TIMELINE + SUCCESS_SUFFIX, CREATE_TIMELINE + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(MAIN_API_SERVICE)}/timelines`,
        method: HttpService.HttpMethods.POST,
        data: {
          title: title,
          text: text,
          date: new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000),
          date_accuracy: date_accuracy,
          topic_id: topic_id
        }
      },
    },
  }
};
