import { Route, Switch } from "react-router-dom";
import NoMatch from "./NoMatch";
import UserList from "./UsersList";
import UserDetails from "./profile/index";
import Dashboard from "./dashboard/Dashboard";
import TopicsList from "./TopicsList";
import TopicPage from "./topics";

const Routes = () => {

  return (
    <>
      {/*<Menu/>*/}
      <Dashboard>
        <Switch>
          {/*<Route exact path="/dash">*/}
          {/*  <Dashboard />*/}
          {/*</Route>*/}
          <Route exact path="/users">
            <UserList/>
          </Route>
          <Route path="/users/:userId">
            <UserDetails/>
          </Route>
          <Route exact path="/topics">
            <TopicsList/>
          </Route>
          <Route path="/topics/:topicId">
            <TopicPage/>
          </Route>
          <Route path="*">
            <NoMatch/>
          </Route>
        </Switch>
      </Dashboard>
    </>
  )
}

export default Routes
