import {getHostUrl, IMAGE_API_SERVICE } from "./environments";
import {FAILURE_SUFFIX, REQUEST_SUFFIX, SUCCESS_SUFFIX } from "./types";
import HttpService from "../services/HttpService";

const SET_IMAGE = 'SET_IMAGE';
const CLEAR_BUFFER = 'CLEAR_BUFFER';

const initialState = {
  imageUrl: '',
  loading: false,
  error: null
};

export const clearBuffer = () => {


  return {
    type: CLEAR_BUFFER,
    payload: {
    },
  }
};

const imageReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case CLEAR_BUFFER:
      return initialState;

    case SET_IMAGE + REQUEST_SUFFIX:
      return {
        ...state,
        imageUrl: '',
        loading: true,
      }

    case SET_IMAGE + SUCCESS_SUFFIX:
      return {
        ...state,
        imageUrl: payload.data.original,
        loading: false
      }

    case SET_IMAGE + FAILURE_SUFFIX:
      return {
        ...state,
        imageUrl: '',
        loading: false
      }

    default:
      return state;
  }
};

export default imageReducer;

export const uploadImage = ({bucket, folderName, imageBytes}) => {
  var bodyFormData = new FormData();
  bodyFormData.append('filedata', imageBytes.substring(imageBytes.indexOf(',') + 1));
  bodyFormData.append('filename', "drafts.png");

  return {
    types: [SET_IMAGE + REQUEST_SUFFIX, SET_IMAGE + SUCCESS_SUFFIX, SET_IMAGE + FAILURE_SUFFIX],
      payload: {
    request: {
      url: `${getHostUrl(IMAGE_API_SERVICE)}/upload/${bucket}/${folderName}/base64`,
      headers: {"Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`},
      method: HttpService.HttpMethods.POST,
      data: bodyFormData
    },
  },
  }
};
