import {getHostUrl, NEWS_AGGREGATOR_SERVICE } from "./environments";
import {FAILURE_SUFFIX, REQUEST_SUFFIX, SUCCESS_SUFFIX } from "./types";
import HttpService from "../services/HttpService";
import {toast} from "react-toastify";

const GET_OPTION_DETAILS = 'GET_OPTION_DETAILS';
const REQUEST_UPDATE_NEWS = 'REQUEST_UPDATE_NEWS';
const GET_AGGREGATED_NEWS = 'GET_AGGREGATED_NEWS';
const GET_NEWS_SOURCES = 'GET_NEWS_SOURCES';
const GET_SEARCH_OPTIONS = 'GET_SEARCH_OPTIONS';
const DELETE_SEARCH_OPTION = 'DELETE_SEARCH_OPTION';
const CREATE_SEARCH_OPTION = 'CREATE_SEARCH_OPTION';
const UPDATE_NEWS_OBJECT = 'UPDATE_NEWS_OBJECT';

const initialState = {
  news: [],
  newsSources: [],
  loadingNews: false,
  subscriptions: [],
  subscriptionsLoading: false,
  subscriptionDetails: {},
  subscriptionDetailsLoading: false,
  sources: [],
  error: null
};
const aggregatedNewsReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case UPDATE_NEWS_OBJECT + REQUEST_SUFFIX:
      return {
        ...state,
        loadingNews: true,
      }

    case UPDATE_NEWS_OBJECT + SUCCESS_SUFFIX:
      return {
        ...state,
        loadingNews: false,
        news: state.news.map(news => {
          if (news.id === payload.data.id) {
            return payload.data;
          }
          return news;
        }),
      }

    case GET_OPTION_DETAILS + REQUEST_SUFFIX:
      return {
        ...state,
        subscriptionDetails: {},
        news: [],
        newsSources: [],
        loadingNews: true,
        subscriptionDetailsLoading: true
      }
    case REQUEST_UPDATE_NEWS + REQUEST_SUFFIX:
      toast.info("News are updating, please wait...");
      return {
        ...state,
      }
    case REQUEST_UPDATE_NEWS + SUCCESS_SUFFIX:
    case GET_OPTION_DETAILS + SUCCESS_SUFFIX:
      return {
        ...state,
        subscriptionDetails: payload.data,
        loadingNews: false,
        subscriptionDetailsLoading: false
      }

    case GET_NEWS_SOURCES + SUCCESS_SUFFIX:
      return {
        ...state,
        sources: payload.data
      }
    case GET_AGGREGATED_NEWS + REQUEST_SUFFIX:
      return {
        ...state,
        news: [],
        newsSources: [],
        loadingNews: true
      }
    case GET_AGGREGATED_NEWS + SUCCESS_SUFFIX:
      if (payload.data.length === 0) {
        toast.info("News haven't been found yet, please try again later");
      }
      return {
        ...state,
        news: payload.data,
        newsSources: [...new Set(payload.data.map(news => news.source).sort((a, b) => new Date(a.published_at) - new Date(b.published_at)))],
        loadingNews: false
      }
    case GET_AGGREGATED_NEWS + FAILURE_SUFFIX:
      return {
        ...state,
        news: [],
        loadingNews: false
      }

    case GET_SEARCH_OPTIONS + REQUEST_SUFFIX:
      return {
        ...state,
        subscriptions: [],
        subscriptionsLoading: true
      }
    case GET_SEARCH_OPTIONS + SUCCESS_SUFFIX:
      return {
        ...state,
        subscriptions: payload.data,
        subscriptionsLoading: false
      }
    case DELETE_SEARCH_OPTION + REQUEST_SUFFIX:
      return {
        ...state,
        subscriptionsLoading: true
      }
    case DELETE_SEARCH_OPTION + SUCCESS_SUFFIX:
      return {
        ...state,
        subscriptions: state.subscriptions.filter(subscription => subscription.id !== payload.data.id),
        subscriptionsLoading: false
      }

    case CREATE_SEARCH_OPTION + REQUEST_SUFFIX:
      return {
        ...state,
        subscriptionsLoading: true
      }

    case CREATE_SEARCH_OPTION + SUCCESS_SUFFIX:
      return {
        ...state,
        subscriptions: [...state.subscriptions, payload.data],
        subscriptionsLoading: false
      }

    default:
      return state;
  }
};

export default aggregatedNewsReducer;

export const getSubscriptions = (topicId) => {
  return {
    types: [GET_SEARCH_OPTIONS + REQUEST_SUFFIX, GET_SEARCH_OPTIONS + SUCCESS_SUFFIX, GET_SEARCH_OPTIONS + FAILURE_SUFFIX],
      payload: {
    request: {
      url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions`,
      params: {
        topic_id: topicId
      }
    },
  },
  }
};

export const deleteSubscription = ({subscriptionId: subscriptionId}) => {
  return {
    types: [DELETE_SEARCH_OPTION + REQUEST_SUFFIX, DELETE_SEARCH_OPTION + SUCCESS_SUFFIX, DELETE_SEARCH_OPTION + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions/${subscriptionId}`,
        method: HttpService.HttpMethods.DELETE
      },
    },
  }
};

export const createSubscription = ({topicId, keyword}) => {
  return {
    types: [CREATE_SEARCH_OPTION + REQUEST_SUFFIX, CREATE_SEARCH_OPTION + SUCCESS_SUFFIX, CREATE_SEARCH_OPTION + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions/create`,
        method: HttpService.HttpMethods.POST,
        data: {
          topic_id: topicId,
          keyword: keyword
        },
        params: {
          topic_id: topicId
        }
      },
    },
  }
};

export const getSubscriptionDetails = (subscriptionId) => {
  return {
    types: [GET_OPTION_DETAILS + REQUEST_SUFFIX, GET_OPTION_DETAILS + SUCCESS_SUFFIX, GET_OPTION_DETAILS + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions/${subscriptionId}`,
        method: HttpService.HttpMethods.GET
      },
    },
  }
}

export const getNews = (subscriptionId) => {
  return {
    types: [GET_AGGREGATED_NEWS + REQUEST_SUFFIX, GET_AGGREGATED_NEWS + SUCCESS_SUFFIX, GET_AGGREGATED_NEWS + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions/${subscriptionId}/mediastack/news`,
        method: HttpService.HttpMethods.GET
      },
    },
  }
}

export const getNewsSources = () => {
  return {
    types: [GET_NEWS_SOURCES + REQUEST_SUFFIX, GET_NEWS_SOURCES + SUCCESS_SUFFIX, GET_NEWS_SOURCES + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions/news/mediastack/available-sources`,
        method: HttpService.HttpMethods.GET
      },
    },
  }
}

//
export const requestNewsUpdate = (subscriptionId) => {
  return {
    types: [REQUEST_UPDATE_NEWS + REQUEST_SUFFIX, REQUEST_UPDATE_NEWS + SUCCESS_SUFFIX, REQUEST_UPDATE_NEWS + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/subscriptions/${subscriptionId}/mediastack/news/update`,
        method: HttpService.HttpMethods.PUT
      },
    },
  }
}

export const linkAggregatedNewsToTopic = ({newsId, topicId, link}) => {
  const request_method = link ?  HttpService.HttpMethods.POST : HttpService.HttpMethods.DELETE;
  const env = localStorage.getItem("environment")
  return {
    types: [UPDATE_NEWS_OBJECT + REQUEST_SUFFIX, UPDATE_NEWS_OBJECT + SUCCESS_SUFFIX, UPDATE_NEWS_OBJECT + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(NEWS_AGGREGATOR_SERVICE)}/management/news/mediastack/${newsId}/publish/${topicId}?environment=${env}`,
        method: request_method
      }
    },
  }
}
