import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {allUsers, approveCreator, deleteUser } from "../modules/users";
import app from "./App";
import {Avatar, Card, CardContent, CardHeader, TextField, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MODERATOR } from "../consts/roles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";


const ControllButtons = styled('div')(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around"
}))

const LeftCentralContentTD = styled('td')(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center"
}))
const UserList = () => {

  const dispatch = useDispatch();
  const { users } = useSelector((state) => state);
  const [ searchInput, setSearchInput ] = useState("")
  const [filteredResults, setFilteredResults] = useState(users);

  const searchUsers = (searchValue) => {
    setSearchInput(searchValue)
  }

  useEffect(() => {
    const users = allUsers();
    dispatch(allUsers())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Dispatch an action to fetch the user data
    dispatch(allUsers());
  }, [dispatch]);

  useEffect(() => {
    if (searchInput) {
      const filteredData = users.filter((user) => {
        var userStringIdentity = ""
        if (user.full_name) {
          userStringIdentity += user.full_name
        }
        if (user.email) {
          userStringIdentity += ` ${user.email}`
        }
        if (user.nickname) {
          userStringIdentity += ` ${user.nickname}`
        }
        console.log(userStringIdentity)
        return userStringIdentity.toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(users)
    }
  }, [searchInput, users])

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TextField
          fullWidth
          label="Search by name, email, username"
          id="fullWidth"
          variant="standard"
          onChange={(e) => searchUsers(e.target.value)}
        />
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Information</TableCell>
              <TableCell>Subscriptions</TableCell>
              <TableCell>Followers</TableCell>
              <TableCell>Published</TableCell>
              <TableCell>Views</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredResults.map((user) => (
            // <Link to={`/users/${user.id}`}>
              <TableRow key={user.id} component={Link} to={`/users/${user.id}/`}>
                <TableCell style={{padding: 0}}>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="user"
                        src={user.image_url}
                        sx={{ width: "64px", height: "64px" }}
                      >
                        {user.full_name}
                      </Avatar>
                    }
                    title={user.full_name}
                    subheader={<><div>{user.email}</div><div>{user.username}</div></>}
                  />
                </TableCell>
                <TableCell><Typography>{user.subscriptions}</Typography></TableCell>
                <TableCell><Typography>{user.followers}</Typography></TableCell>
                <TableCell><Typography>{user.publications}</Typography></TableCell>
                <TableCell><Typography>{user.views}</Typography></TableCell>
              </TableRow>
            // </Link>
          ))}
            </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
}

export default UserList
// <ControllButtons>
// {user.creator_approved?
//     <button className="btn btn-xs btn-danger p-2" onClick={() => dispatch(approveCreator(user, false))}>
//       Revoke creator
//     </button> :
//     <button className="btn btn-xs btn-success p-2" onClick={() => dispatch(approveCreator(user, true))}>
//       Approve User
//     </button>
// }
// {
//   Array.isArray(user.roles) && user.roles.includes(MODERATOR)?
//     <button className="btn btn-xs btn-danger p-2" onClick={() => dispatch(approveCreator(user, false))}>
//       Revoke moderator
//     </button> :
//     <button className="btn btn-xs btn-success p-2" onClick={() => dispatch(approveCreator(user, true))}>
//       Add moderator
//     </button>
// }
// </ControllButtons>
