import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {ButtonGroup} from "@mui/material";


const Counter = ({count, type}) => (
 <div style={{
   display: "flex",
   alignItems: "center",
   justifyContent: "space-between",
   flexDirection: "column"
 }}>
   <Typography variant={"h4"}>
     {count}
   </Typography>
   <Typography>
     {type}
   </Typography>
 </div>
)
function ControlTopic({published, publishTopic, topicId}) {
    const dispatch = useDispatch();

    return (
        <Paper style={{
          padding: "15px",
          margin: "5px"
        }}>
          <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}>
            <Counter type={"Articles"} count={120} />
            <Counter type={"Timelines"} count={48} />
          </div>
          <ButtonGroup orientation="vertical" variant="outlined" aria-label="outlined button group" fullWidth={true}>
            {
              published?
                <Button variant="outlined" color="warning" onClick={() => dispatch(publishTopic(topicId, false))}>
                  Unpublish topic
                </Button> :
                <Button variant="outlined" color="success" onClick={() => dispatch(publishTopic(topicId, true))}>
                  Publish topic
                </Button>
            }
          </ButtonGroup>
        </Paper>
    );
}

ControlTopic.propTypes = {
  published: PropTypes.number.isRequired,
  publishTopic: PropTypes.func.isRequired,
  topicId: PropTypes.string.isRequired
};

export default ControlTopic;
