import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import StoreService from "../services/StoreService";
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import Welcome from "./Welcome";




const store = StoreService.setup();



const App = () => (


  <Provider store={store}>

      <BrowserRouter>

          <RenderOnAnonymous>
            <Welcome/>
          </RenderOnAnonymous>
          <RenderOnAuthenticated>
            <Routes/>
          </RenderOnAuthenticated>

      </BrowserRouter>

  </Provider>

);

export default App;
