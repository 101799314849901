import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {Link} from "react-router-dom";
import {allUsers, approveCreator, approveModerator} from '../../modules/users';
import * as PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import * as React from "react";
import CardMainInformation from "./cardMainInformation";
import Statistics from "./counterContainer";
import CryptoInfo from "./cryptoInfoContainer";
import Articles from "./articles";
import ExperienceInfo from "./experienceContainer";



function LocationOn(props) {
  return null;
}

LocationOn.propTypes = {sx: PropTypes.shape({color: PropTypes.any})};

function Edit(props) {
  return null;
}

Edit.propTypes = {sx: PropTypes.shape({fontSize: PropTypes.number})};
const UserDetails = () => {

  const { userId } = useParams();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state);
  const [user, setUser] = useState();

  useEffect(() => {
    dispatch(allUsers())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUser(users.find(user => user.id === userId))
  }, [userId, users]);
  /*
 views
 created
   */
  return user ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CardMainInformation
            fullName={user.full_name}
            avatar={user.image_url}
            roles={user.roles}
            mobilePhone={user.phone}
            email={user.email}
            origin={user.origin}
          />
        </Grid>
        <Grid item xs={8}>
          <Statistics
            followers={user.followers}
            views={user.views}
            published={user.publications}
            subscriptions={user.subscriptions}
            roles={user.roles}
            approveCreator={approveCreator}
            approveModerator={approveModerator}
            userId={user.id}
          />
          <CryptoInfo userId={user.id} />
          <ExperienceInfo userId={user.id} />
          <Articles userId={user.id} />
        </Grid>
      </Grid>
      <Link to="/users">&laquo; back to list</Link>
    </>
  ) : null
}

export default UserDetails
