import ReactDOM from "react-dom";
import App from "./components/App";
import HttpService from "./services/HttpService";
import UserService from "./services/UserService";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const renderApp = () => ReactDOM.render(<>
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    style={{ zIndex: 9999 }}
  />
    <App/>
  </>, document.getElementById("app"));

UserService.initKeycloak(renderApp);
HttpService.configure();
