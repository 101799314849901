import {getHostUrl, WALLET_SERVICE} from "./environments";
import {FAILURE_SUFFIX, REQUEST_SUFFIX, SUCCESS_SUFFIX } from "./types";

const GET_WALLET = 'GET_WALLET';

const initialState = {
  wallets: [],
  loading: false,
  error: null
};

const walletReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case GET_WALLET + REQUEST_SUFFIX:
      return {
        ...state,
        loading: true,
      };

    case GET_WALLET + SUCCESS_SUFFIX:
      return {
        ...state,
        wallets: payload.data,
        loading: false,
      };

    default:
      return state;
  }
};

export default walletReducer;

export const getWallet = (userId) => {
  return {
    types: [GET_WALLET + REQUEST_SUFFIX, GET_WALLET + SUCCESS_SUFFIX, GET_WALLET + FAILURE_SUFFIX],
      payload: {
    request: {
      url: `${getHostUrl(WALLET_SERVICE)}/users/${userId}/wallet`,
    },
  },
  }
};
