import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {ButtonGroup, Typography} from "@mui/material";
import {getSubscriptions} from "../../../modules/aggregatedNews";
import Grid from "@mui/material/Grid";
import {openForm} from "../../../modules/form";
import CreateSubscription from "./createSubscription";
import SubscriptionManagement from "./subscriptionManagement";


const Subscription = ({topicId, subscriptionId, keyword}) => {
  const dispatch = useDispatch();

  return (
  <Button variant="outlined" color="success" onClick={() => dispatch(openForm({
    title: "Get news from external sources",
    description: "Select a news to move to timelines",
    content: <SubscriptionManagement topicId={topicId} subscriptionId={subscriptionId} /> }))} style={{
      height: "100%"
  }}>
    {keyword}
  </Button>
)};
function MediastackSubscriptions({topicId}) {
    const dispatch = useDispatch();


    const { aggregatedNews } = useSelector((state) => state);
    const [subscriptions, setSubscriptions] = useState(aggregatedNews.subscriptions);

    useEffect(() => {
      dispatch(getSubscriptions(topicId))
    }, []);

    useEffect(() => {
      if (aggregatedNews.subscriptions) {
        setSubscriptions(aggregatedNews.subscriptions)
      }}, [aggregatedNews.subscriptionsLoading]);



    return (
        <Paper style={{
          padding: "15px",
          margin: "5px"
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"h5"}>
                Mediastack linked search
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {
                  subscriptions.map((item) => (
                    <Grid item>
                      <Subscription topicId={topicId} subscriptionId={item.id} keyword={item.keyword} />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup
                orientation="vertical"
                variant="outlined"
                aria-label="outlined button group"
                fullWidth={true}
                style={{marginTop: "5px"}}>
                <Button variant="outlined" color="success" onClick={() => dispatch(openForm({
                    title: "Create mediastack subscription",
                    description: "This form will create a news subscription for this topic",
                    content: <CreateSubscription topicId={topicId} /> }))}>
                  Create subscription
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
    );
}

MediastackSubscriptions.propTypes = {
  topicId: PropTypes.string.isRequired
};

export default MediastackSubscriptions;
