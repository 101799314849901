import {useEffect, useState} from "react";
import * as React from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  deleteSubscription,
  getNews,
  getSubscriptionDetails, linkAggregatedNewsToTopic,
  requestNewsUpdate
} from "../../../modules/aggregatedNews";
import {Downloading} from "@mui/icons-material";
import {closeForm} from "../../../modules/form";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {RoundedClickableContainer} from "../../elements/text";
import {DEVELOPMENT, LOCAL, PRODUCTION, TEST} from "../../../modules/environments";



const SubscriptionManagement = ({topicId, subscriptionId}) => {
  const dispatch = useDispatch();

  const { aggregatedNews } = useSelector((state) => state);

  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [news, setNews] = useState(aggregatedNews.news);
  const [sources, setSources] = useState([]);
  const [availableSources, setAvailableSources] = useState(aggregatedNews.newsSources);
  const [sortBy, setSortBy] = useState("oldest");

  useEffect(() => {setAvailableSources(aggregatedNews.newsSources)}, [aggregatedNews.newsSources])

  useEffect(() => {
    console.log("State of sortBy: ", sortBy)
    if (sources.length === 0) {
      if (sortBy === "-") {
        setNews(aggregatedNews.news)
      } else if (sortBy === "recent") {
        console.log("Sorting by recent")
        setNews(aggregatedNews.news.sort((a, b) => new Date(a.published_at) - new Date(b.published_at)))
      } else if (sortBy === "oldest") {
        setNews(aggregatedNews.news.sort((a, b) => new Date(b.published_at) - new Date(a.published_at)))
      }
    } else {
      if (sortBy === "-") {
        setNews(aggregatedNews.news.filter((news) => sources.includes(news.source)))
      } else if (sortBy === "recent") {
        setNews(aggregatedNews.news.filter((news) => sources.includes(news.source)).sort((a, b) => new Date(a.published_at) - new Date(b.published_at)))
      } else if (sortBy === "oldest") {
        setNews(aggregatedNews.news.filter((news) => sources.includes(news.source)).sort((a, b) => new Date(b.published_at) - new Date(a.published_at)))
      }
    }
  }, [sources, sortBy])
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSources(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    dispatch(getSubscriptionDetails(subscriptionId));
  }, [])

  useEffect(() => {setSubscriptionDetails(aggregatedNews.subscriptionDetails)}, [aggregatedNews.subscriptionDetailsLoading])

  useEffect(() => {
    setNews(aggregatedNews.news)
  }, [aggregatedNews.loadingNews])

  const validateAndSendDeleteRequest = () => {
    dispatch(deleteSubscription({subscriptionId: subscriptionId}));
    dispatch(closeForm());
  }

  const validateAndSendUpdateRequest = () => {
    dispatch(requestNewsUpdate(subscriptionId));
  }

  const validateAndSendGetRequest = () => {
    dispatch(getNews(subscriptionId));
  }

  let content = null;
  if (!subscriptionDetails || !subscriptionDetails.keyword) {
    content = (<div><Downloading /></div>)
  } else {
    content = (<>
      <Grid container columns={1} spacing={2}>
        <Grid item xs={1} >
          <TextField
            fullWidth
            label="Keywords"
            id="fullWidth"
            variant="standard"
            value={subscriptionDetails.keyword}
            disabled
          />
        </Grid>
        <Grid item xs={1} >
          <TextField
            fullWidth
            label="Update requested date"
            id="fullWidth"
            variant="standard"
            value={
              subscriptionDetails.update_requested_date? new Date(subscriptionDetails.update_requested_date).
              toLocaleString('en-us', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).
              replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2') : "Never"
            }
            disabled
          />
          <Typography variant="caption" display="block" gutterBottom>
            Min interval between updates 10 minutes
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Button fullWidth={true} variant="outlined" color="error" onClick={() => validateAndSendDeleteRequest()}>
            Delete
          </Button>
        </Grid>
        <Grid item xs={1} >
          <Button fullWidth={true} variant="outlined" color="info" onClick={() => validateAndSendUpdateRequest()}>
            Request Update
          </Button>
        </Grid>
        <Grid item xs={1} >
          <Button fullWidth={true} variant="outlined" color="success" onClick={() => validateAndSendGetRequest()}>
            Get News
          </Button>
        </Grid>
        <Grid item xs={1} >
          <Divider />
        </Grid>
        {news.length === 0 && (<Grid item xs={1} > <Typography gutterBottom>
          Request news to see them here. It is better to request update once per day.
          We are limited on a number of requests per month.
        </Typography></Grid>)}
      </Grid>
      <Grid container pacing={2} justifyContent={"space-between"}>
        {news.length > 0? (<>
              <Grid item xs={8}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-multiple-chip-label">Sources</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    variant="standard"
                    value={sources}
                    onChange={handleChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: "300px" }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {availableSources.map((source) => (
                      <MenuItem
                        key={source}
                        value={source}
                      >
                        {source}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-error-label">Sort by</InputLabel>
                  <Select
                    value={sortBy}
                    label="Sort By"
                    onChange={(event) => setSortBy(event.target.value)}
                  >
                    <MenuItem value={"recent"}>Most recent</MenuItem>
                    <MenuItem value={"oldest"}>Oldest</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
        </>) : null}
      </Grid>
      <Grid container columns={1} spacing={2}>
        {news.map((aggregatedNewsItem) => {
            if (aggregatedNewsItem.linked_timelines.includes(topicId)) {
              return  (<Grid item xs={1}>
                <RoundedClickableContainer style={{color: 'green'}} onClick={() => dispatch(linkAggregatedNewsToTopic({
                  newsId: aggregatedNewsItem.id,
                  topicId: topicId,
                  link: false
                }))}>
                  <Typography>{aggregatedNewsItem.title}</Typography>
                  <Typography>{aggregatedNewsItem.description}</Typography>
                  <Chip key={aggregatedNewsItem.source} label={aggregatedNewsItem.source} />
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography>#{aggregatedNewsItem.category}</Typography>
                    <Typography>{aggregatedNewsItem.published_at}</Typography>
                  </div>
                </RoundedClickableContainer>
              </Grid>)
            }
            return (<Grid item xs={1}>
              <RoundedClickableContainer onClick={() => dispatch(linkAggregatedNewsToTopic({
                  newsId: aggregatedNewsItem.id,
                  topicId: topicId,
                  link: true
                }))}>
                <Typography>{aggregatedNewsItem.title}</Typography>
                <Typography>{aggregatedNewsItem.description}</Typography>
                <Chip key={aggregatedNewsItem.source} label={aggregatedNewsItem.source} />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Typography>#{aggregatedNewsItem.category}</Typography>
                  <Typography>{aggregatedNewsItem.published_at}</Typography>
                </div>
              </RoundedClickableContainer>
            </Grid>)
          })
        }
      </Grid>
      </>)
  }



  return (
    <div style={{height: "100%", width: "700px"}}>
      {content}
    </div>
  )
}

export default SubscriptionManagement;
