import {styled} from "@mui/material/styles";
import {FormControl, TextField} from "@mui/material";
import {Link} from "react-router-dom";


export const TextFieldTopSpaced = styled(TextField)(() => ({
  marginTop: "15px",
}))

export const FormControlTopSpaced = styled(FormControl)(() => ({
  marginTop: "15px",
}))


export const RoundedClickableContainer = styled('div')(() => ({
  borderRadius: "15px",
  border: "1px solid #02c4c4",
  '&:hover': {
    border: "1px solid aqua",
  },
  padding: "15px",
  marginTop: "10px",
  marginBottom: "10px",
  cursor: "pointer"
}))

export const LinkNoStyles = styled(Link)(() => ({
  textDecoration: 'none'
}))
