import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";
import UserService from "../services/UserService";
import {getHostUrl, USER_SERVICE} from "./environments";

const LIST_USERS = 'LIST_USERS';
const ADD_USER = 'ADD_USER';
const DELETE_USER = 'DELETE_USER';
const SET_CREATOR = 'SET_CREATOR';
const REVOKE_CREATOR = 'REVOKE_CREATOR';

const SET_MODERATOR = 'SET_MODERATOR';
const REVOKE_MODERATOR = 'REVOKE_MODERATOR';

const usersReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_USERS + SUCCESS_SUFFIX:
      return action.payload.data;

    case SET_MODERATOR + SUCCESS_SUFFIX:
    case REVOKE_MODERATOR + SUCCESS_SUFFIX:
    case SET_CREATOR + SUCCESS_SUFFIX:
    case REVOKE_CREATOR + SUCCESS_SUFFIX:
      return state.map(user => {
        if (user.id === action.payload.data.id) {
          return action.payload.data;
        }
        return user;
      })

    case DELETE_USER:
      return state.filter((user) => user.id !== action.payload.user.id);

    default:
      return state;
  }
};

export default usersReducer;

export const allUsers = () => {
  return {
    type: LIST_USERS,
      payload: {
    request: {
      url: `${getHostUrl(USER_SERVICE)}/users?limit=1000`,
    },
  },
  }
};

export const addUser = user => {
  console.log(`${UserService.getUsername()} added the user ${user.title}`);
  return {
    type: ADD_USER,
    payload: {
      request: {
        url: `${getHostUrl(USER_SERVICE)}/users`,
        method: HttpService.HttpMethods.POST,
        data: user,
      },
    },
  }
};

export const deleteUser = user => {
  console.log(`${UserService.getUsername()} deletes the user ${user.title}`);
  return {
    type: DELETE_USER,
    payload: {
      user,
      request: {
        url: `${getHostUrl(USER_SERVICE)}/users/${user.id}`,
        method: HttpService.HttpMethods.DELETE,
      },
    },
  }
};

export const approveCreator = (user_id, approve) => {
  const request_method = approve ?  HttpService.HttpMethods.PUT : HttpService.HttpMethods.DELETE;
  const request_type = approve ?  SET_CREATOR : REVOKE_CREATOR;
  return {
    type: request_type,
    payload: {
      user_id,
      request: {
        url: `${getHostUrl(USER_SERVICE)}/creators/${user_id}/approve`,
        method: request_method,
      },
    },
  }
}


export const approveModerator = (user_id, approve) => {
  const request_method = approve ?  HttpService.HttpMethods.PUT : HttpService.HttpMethods.DELETE;
  const request_type = approve ?  SET_MODERATOR : REVOKE_MODERATOR;
  return {
    type: request_type,
    payload: {
      user_id,
      request: {
        url: `${getHostUrl(USER_SERVICE)}/moderators/${user_id}/approve`,
        method: request_method,
      },
    },
  }
}
