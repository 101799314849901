import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import {getWallet} from "../../modules/wallet";
import {toast} from "react-toastify";

const WalletDetails = ({currency, address, amount}) => {

  const copyWithAlert = () => {
    toast.info("Address copied to clipboard")
    navigator.clipboard.writeText(address)
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        cursor: "pointer"
      }}
      onClick={() => copyWithAlert()}>
      <div style={{display: "flex", alignItems: "center"}}>
        <Typography variant={"h4"}>
          {amount}
        </Typography>
        <Typography variant={"h4"} style={{margin: "10px"}}>
          {currency.toUpperCase()}
        </Typography>
      </div>
      <Typography>
        {address}
      </Typography>
    </div>
  )
}
function CryptoInfo({userId}) {
    const dispatch = useDispatch();

    const { walletsState } = useSelector((state) => state);

    useEffect(() => {
      dispatch(getWallet(userId))
    }, []);


    let content = null

    if (walletsState.loading) {
      content = (
        <Typography variant={"h4"}>
          Loading wallets
        </Typography>
      )
    } else {
      content = (
        <>
          {
            Array.isArray(walletsState.wallets) && walletsState.wallets.length? <></>: <Typography variant={"h4"}>
              No wallet connected
            </Typography>
          }
          {walletsState.wallets.map((wallet_details) => (
            <WalletDetails
              address={wallet_details.address}
              currency={wallet_details.currency}
              amount={wallet_details.amount}
            />
          ))}
        </>
      )
    }

    return (
        <Paper style={{
          padding: "15px",
          margin: "5px"
        }}>
          <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}>
            {content}
          </div>
        </Paper>
    );
}

CryptoInfo.propTypes = {
  userId: PropTypes.string.isRequired
};

export default CryptoInfo;
