import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {getTimelineTopics} from "../../modules/topics";
import TopicInformation from "./topicInformation";
import ControlTopic from "./controllTopic";
import Timelines from "./timelines";
import AggregatedNewsOptions from "./aggregator/subscriptions";

const TopicPage = () => {

  const { topicId } = useParams();
  const dispatch = useDispatch();
  const { topicsState } = useSelector((state) => state);
  const [topic, setTopic] = useState();

  useEffect(() => {
    dispatch(getTimelineTopics())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTopic(topicsState.topics.find(topic => topic.id === topicId))
  }, [topicId, topicsState.topics]);
  /*
 views
 created
   */
  return topic ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TopicInformation
            title={topic.title}
            image_url={topic.image_url}
            description={topic.roles}
          />
          <ControlTopic published={topic.published} topicId={topic.id} publishTopic={console.log}/>
        </Grid>
        <Grid item xs={8}>
          <AggregatedNewsOptions topicId={topic.id} />
          <Timelines topicId={topic.id} />
        </Grid>
      </Grid>
      <Link to="/topics">&laquo; back to list</Link>
    </>
  ) : null
}

export default TopicPage;
