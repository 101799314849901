

export const DEVELOPMENT = "DEVELOPMENT";
export const PRODUCTION = "PRODUCTION";
export const TEST = "TEST";
export const LOCAL = "LOCAL";
export const USER_SERVICE = "USER_SERVICE";
export const WALLET_SERVICE = "WALLET_SERVICE";
export const ARTICLES_SERVICE = "ARTICLES_SERVICE";
export const MAIN_API_SERVICE = "MAIN_API_SERVICE";
export const IMAGE_API_SERVICE = "IMAGE_API_SERVICE";

export const NEWS_AGGREGATOR_SERVICE = "NEWS_AGGREGATOR_SERVICE";

const MAP_SERVICES = {
  PRODUCTION: {
    USER_SERVICE: "https://api.glidehq.io",
    WALLET_SERVICE: "https://api.glidehq.io",
    ARTICLES_SERVICE: "https://api.glidehq.io",
    MAIN_API_SERVICE: "https://api.glidehq.io",
    IMAGE_API_SERVICE: "https://image.glidehq.io",
    NEWS_AGGREGATOR_SERVICE: "https://aggregator.api.glidehq.io"
  },
  DEVELOPMENT: {
    USER_SERVICE: "https://dev.api.glidehq.io",
    WALLET_SERVICE: "https://dev.api.glidehq.io",
    ARTICLES_SERVICE: "https://dev.api.glidehq.io",
    MAIN_API_SERVICE: "https://dev.api.glidehq.io",
    IMAGE_API_SERVICE: "https://image.glidehq.io",
    NEWS_AGGREGATOR_SERVICE: "https://aggregator.api.glidehq.io"
  },
  TEST: {
    USER_SERVICE: "https://test.api.glidehq.io",
    WALLET_SERVICE: "https://test.api.glidehq.io",
    ARTICLES_SERVICE: "https://test.api.glidehq.io",
    MAIN_API_SERVICE: "https://test.api.glidehq.io",
    IMAGE_API_SERVICE: "https://image.glidehq.io",
    NEWS_AGGREGATOR_SERVICE: "https://aggregator.api.glidehq.io"
  },
  LOCAL: {
    USER_SERVICE: "/api",
    WALLET_SERVICE: "/api",
    ARTICLES_SERVICE: "/api",
    MAIN_API_SERVICE: "/api",
    IMAGE_API_SERVICE: "https://image.glidehq.io",
    NEWS_AGGREGATOR_SERVICE: "http://127.0.0.1:8003"
  }
}


export const getHostUrl = (service) => {
  const env = localStorage.getItem("environment");
  if ([LOCAL, DEVELOPMENT, PRODUCTION, TEST].indexOf(env) === -1) {
    return MAP_SERVICES[DEVELOPMENT][service]
  }
  return MAP_SERVICES[env][service]

}
