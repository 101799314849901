import React from "react";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from "@mui/material/Divider";
import {Stack} from "@mui/material";
import Chip from "@mui/material/Chip";


const RowInformation = ({type, value}) => (
  <div style={{display: "flex", justifyContent: "space-between"}}>
    <Typography>
      {type}
    </Typography>
    <Typography>
      {value ? <>{value}</> : <>-</>}
    </Typography>
  </div>
)

function Article({title, summary, data, tags, comments, views, image_url}) {
  return (
    <Card style={{margin: "5px", width: "100%"}}>
      <CardMedia
        image={image_url}
        style={{
          height: 400
        }}
        title={title}
      />
      <CardContent>
        <Typography component={"h4"}>
          {summary}
        </Typography>
        <Divider/>
        <RowInformation type={"Comments"} value={comments} />
        <RowInformation type={"Views"} value={views} />
        <Stack direction="row" spacing={1} style={{padding: "15px"}}>
          {tags && tags.map(tag => (
            <Chip label={tag.name} color="primary" variant="outlined" />
          )) }
        </Stack>
      </CardContent>
    </Card>
  );
}

Article.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  comments: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired
};

export default Article;
