import {useState} from "react";
import * as React from "react";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {createSubscription} from "../../../modules/aggregatedNews";
import Grid from "@mui/material/Grid";



const CreateSubscription = ({topicId}) => {
  const dispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = useState("");


  const validateAndSendRequest = () => {
    if (!searchKeyword) {
      toast.warning("Keywords are required");
    } else {
      dispatch(createSubscription({
        topicId: topicId,
        keyword: searchKeyword
      }));
    }
  }


  return (
    <Grid container columns={1} spacing={2}>
        <Grid item xs={1} >
          <TextField
            fullWidth
            label="Keywords"
            id="fullWidth"
            variant="standard"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </Grid>
      <Grid item xs={1} >
        <Button fullWidth={true} variant="outlined" color="success" onClick={() => validateAndSendRequest()}>
          Create Subscription
        </Button>
      </Grid>
    </Grid>
  )
}

export default CreateSubscription;
