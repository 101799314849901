import React from "react";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from "@mui/material/Divider";
import {Stack} from "@mui/material";
import Chip from "@mui/material/Chip";


const RowInformation = ({type, value}) => (
  <div style={{display: "flex", justifyContent: "space-between"}}>
    <Typography>
      {type}
    </Typography>
    <Typography>
      {value ? <>{value}</> : <>-</>}
    </Typography>
  </div>
)

function CardMainInformation({fullName, avatar, roles, mobilePhone, email, origin}) {
    return (
        <Card style={{margin: "5px"}}>
          <CardMedia
            image={avatar}
            style={{
              height: 400
            }}
            title={fullName}
          />
            <CardContent>
              <Typography>
                {fullName}
              </Typography>
              <Divider/>
              <RowInformation type={"Email"} value={email} />
              <RowInformation type={"Phone"} value={mobilePhone} />
              <RowInformation type={"Origin"} value={origin} />
              <Stack direction="row" spacing={1} style={{padding: "15px"}}>
                {roles && roles.map(roleName => (
                  <Chip label={roleName} color="primary" variant="outlined" />
                )) }
              </Stack>
            </CardContent>
        </Card>
    );
}

CardMainInformation.propTypes = {
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    mobilePhone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired
};

export default CardMainInformation;
