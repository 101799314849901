import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import {getUserExperiences} from "../../modules/experience";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {CREATOR} from "../../consts/roles";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";

const ExpirienceDetails = ({user_id, job_title, company_name, range_start, range_end, image_url, is_approved, approveAction}) => {

  return (
    <Grid container spacing={2} style={{margin: "10px"}}>
      <Grid item xs={2}>
        <Typography>
          {range_start}2022-02-02
        </Typography>
        <Typography>
          {range_end}2023-02-02
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem xs={1} />
      <Grid item xs={7}>
        <Typography variant={"h4"}>
          {company_name}
        </Typography>
        <Typography style={{margin: "10px"}}>
          {job_title.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {
          is_approved?
            <Button fullWidth={true} variant="outlined" color="warning" onClick={() => approveAction(user_id, false)}>
              reject
            </Button> :
            <Button fullWidth={true} variant="outlined" color="success" onClick={() => approveAction(user_id, true)}>
              approve
            </Button>
        }
      </Grid>
    </Grid>
  )
}

function ExperienceInfo({userId}) {
    const dispatch = useDispatch();

    const { experiencesState } = useSelector((state) => state);

    useEffect(() => {
      dispatch(getUserExperiences(userId));
    }, []);

    const dispatchApproveAction = (userId, is_approved) => {
      toast.warning("Not implemented yet");
    }


    let content = null

    if (experiencesState.loading) {
      content = (
        <Typography variant={"h4"}>
          Loading user experiences
        </Typography>
      )
    } else {
      content = (
        <>
          {
            Array.isArray(experiencesState.experiences) && experiencesState.experiences.length? <></>: <div style={{
              display: "flex",
              justifyContent: "center"
            }}><Typography variant={"h4"}>
              No experiences
            </Typography></div>
          }
          {experiencesState.experiences.map((experience_details) => (
            <ExpirienceDetails
              user_id={experience_details.user_id}
              job_title={experience_details.job_title}
              company_name={experience_details.company_name}
              range_start={experience_details.range_start}
              range_end={experience_details.range_end}
              image_url={experience_details.image_url}
              is_approved={experience_details.is_approved}
              approveAction={dispatchApproveAction}
            />
          ))}
        </>
      )
    }

    return (
        <Paper style={{
          padding: "15px",
          margin: "5px"
        }}>
          {content}
        </Paper>
    );
}

ExperienceInfo.propTypes = {
  userId: PropTypes.string.isRequired
};

export default ExperienceInfo;
