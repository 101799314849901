import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {Route} from "react-router-dom";
import UserService from "../../services/UserService";
import AccessDenied from "../AccessDenied";
import Button from "@mui/material/Button";
import {MODERATOR, CREATOR} from "../../consts/roles";
import {approveModerator} from "../../modules/users";
import {useDispatch} from "react-redux";
import {ButtonGroup} from "@mui/material";


const Counter = ({count, type}) => (
 <div style={{
   display: "flex",
   alignItems: "center",
   justifyContent: "space-between",
   flexDirection: "column"
 }}>
   <Typography variant={"h4"}>
     {count}
   </Typography>
   <Typography>
     {type}
   </Typography>
 </div>
)
function Statistics({followers, published, views, subscriptions, roles, approveCreator, approveModerator, userId}) {
    const dispatch = useDispatch();

    return (
        <Paper style={{
          padding: "15px",
          margin: "5px"
        }}>
          <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}>
            <Counter type={"Followers"} count={followers} />
            <Counter type={"Published"} count={published} />
            <Counter type={"Views"} count={views} />
            <Counter type={"Subscriptions"} count={subscriptions} />
          </div>
          <ButtonGroup orientation="vertical" variant="outlined" aria-label="outlined button group" fullWidth={true}>
            {
              Array.isArray(roles) && roles.includes(MODERATOR)?
                <Button fullWidth={true} variant="outlined" color="warning" onClick={() => dispatch(approveModerator(userId, false))}>
                  Revoke moderator
                </Button> :
                <Button fullWidth={true} variant="outlined" color="success" onClick={() => dispatch(approveModerator(userId, true))}>
                  Add moderator
                </Button>
            }
            {
              Array.isArray(roles) && roles.includes(CREATOR)?
                <Button fullWidth={true} variant="outlined" color="warning" onClick={() => dispatch(approveCreator(userId, false))}>
                  Revoke creator
                </Button> :
                <Button fullWidth={true} variant="outlined" color="success" onClick={() => dispatch(approveCreator(userId, true))}>
                  Add creator
                </Button>
            }
          </ButtonGroup>
        </Paper>
    );
}

Statistics.propTypes = {
  followers: PropTypes.number.isRequired,
  published: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired,
  roles: PropTypes.array.isRequired,
  approveCreator: PropTypes.func.isRequired,
  approveModerator: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

export default Statistics;
