import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {toast} from "react-toastify";
import {deleteTimeline, getTimelines, patchTimeline, publishTimeline, saveTimeline} from "../../modules/timelines";
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {Backdrop, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import Modal from "react-overlays/Modal";
import IconButton from "@mui/material/IconButton";
import {AddCircleOutlined} from "@mui/icons-material";
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {openForm} from "../../modules/form";

const TimelineDetails = ({id, title, text, date, date_accuracy, views, created, updated, published, topicId}) => {
  const dispatch = useDispatch();

  const [titleValue, setTitleValue] = React.useState(title);
  const [textValue, setTextValue] = React.useState(text);
  const [dateValue, setDateValue] = React.useState(date);
  const [dateAccuracy, setDateAccuracy] = React.useState(0);
  const [publishedValue, setPublishedValue] = React.useState(published);

  const [modificationMode, setModificationMode] = React.useState(false);
  const [inputDateFormat, setInputDateFormat] = React.useState("YYYY-MM-DD");
  const [datetimePicketViews, setDatetimePicketViews] = React.useState([]);

  useEffect(() => {if (!id) {setModificationMode(true)}}, [])

  useEffect(() => {
    setDateAccuracy(parseInt(date_accuracy, 10))
  }, [date_accuracy])

  useEffect(() => {
    setPublishedValue(published)
  }, [published])

  useEffect(() => {
    if (dateAccuracy === 0) {
      // year
      setDatetimePicketViews(['year'])
      setInputDateFormat("YYYY")
    } else if (dateAccuracy === 1) {
      // month
      setDatetimePicketViews(['year', 'month'])
      setInputDateFormat("YYYY-MM")
    } else if (dateAccuracy === 2) {
      // day
      setDatetimePicketViews(['year', 'month', 'day'])
      setInputDateFormat("YYYY-MM-DD")
    } else if (dateAccuracy === 3) {
      // hour
      setDatetimePicketViews(['year', 'month', 'day', 'hours'])
      setInputDateFormat("YYYY-MM-DD HH")
    } else if (dateAccuracy === 4) {
      // minute
      setDatetimePicketViews(['year', 'month', 'day', 'hours', 'minutes'])
      setInputDateFormat("YYYY-MM-DD HH:mm")
    } else {
      // second
      setDatetimePicketViews(['year', 'month', 'day', 'hours', 'minutes'])
      setInputDateFormat("YYYY-MM-DD HH:mm")
    }
  }, [dateAccuracy]);


  const sendPatchTimelineRequest = () => {
    setModificationMode(false)
    dispatch(patchTimeline({id, title: titleValue, text: textValue, date: dateValue, date_accuracy: dateAccuracy}))
  }

  const sendSaveTimelineRequest = () => {
    console.log(dateValue);
    dispatch(saveTimeline({title: titleValue, text: textValue, date: dateValue, date_accuracy: dateAccuracy, topic_id: topicId}))
  }

  const declineModification = () => {
    setDateAccuracy(date_accuracy)
    setTitleValue(title)
    setTextValue(text)
    setDateValue(date)
    setModificationMode(false)
  }

  const sendPublishTimelineRequest = (publishState) => {
    dispatch(publishTimeline({id, publishState: publishState}))
    setPublishedValue(publishState)
  }

  let controlButtons = null
  if (id) {
    controlButtons = (<>
      <div>
        {publishedValue ?
          <IconButton color="primary" component="label" onClick={() => sendPublishTimelineRequest(false)}>
            <UnpublishedOutlinedIcon />
          </IconButton>
          :
          <IconButton color="success" component="label" onClick={() => sendPublishTimelineRequest(true)}>
            <PublishOutlinedIcon />
          </IconButton>
        }
        <IconButton color="error" component="label" onClick={() => dispatch(deleteTimeline(id))}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
      <div>
        {modificationMode ?
          <>
            <IconButton color="warning" component="label" onClick={() => declineModification()}>
              <HistoryOutlinedIcon />
            </IconButton>
            <IconButton color="success" component="label" onClick={() => sendPatchTimelineRequest()}>
              <SaveOutlinedIcon />
            </IconButton>
          </>
          :
          <IconButton color="primary" component="label" onClick={() => setModificationMode(true)}>
            <SettingsApplicationsOutlinedIcon />
          </IconButton>
        }
      </div>
    </>)
  } else {
    controlButtons = (<><IconButton color="success" component="label" onClick={() => sendSaveTimelineRequest()}>
      <SendOutlinedIcon />
    </IconButton></>)
  }


  return (
  <div style={{paddingBottom: "50px"}}>
    <Grid container spacing={2} justifyContent={"space-between"}>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
          <DateTimePicker
            value={dateValue}
            label="Date"
            disabled={!modificationMode}
            variant="outline"
            inputFormat={inputDateFormat}
            onChange={setDateValue}
            views={datetimePicketViews}
            renderInput={(params) =>
              <TextField id="fullWidth" variant="standard" {...params} />} />
        </LocalizationProvider>
        <FormControl fullWidth variant="standard">
          <InputLabel id="demo-simple-select-label">Accuracy</InputLabel>
          <Select
            labelId="date-accuracy"
            id="date-accuracy"
            disabled={!modificationMode}
            value={dateAccuracy}
            label="Accuracy"
            onChange={(e) => setDateAccuracy(e.target.value)}
          >
            <MenuItem value={0}>Year</MenuItem>
            <MenuItem value={1}>Month</MenuItem>
            <MenuItem value={2}>Day</MenuItem>
            <MenuItem value={3}>Hour</MenuItem>
            <MenuItem value={4}>Minute</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Divider orientation="vertical" flexItem xs={1} style={{marginLeft: "10px", marginRight: "5px" }} />
      <Grid item xs={7}>
        {modificationMode ?
          <>
            <TextField
              id="fullWidth"
              variant="standard"
              multiline
              fullWidth={true}
              defaultValue={titleValue}
              onChange={(e) => setTitleValue(e.target.value)}
            />
            <TextField
              id="fullWidth"
              variant="standard"
              multiline
              fullWidth={true}
              defaultValue={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </>
          :
          <>
            <Typography variant={"h4"}>
              {titleValue}
            </Typography>
            <Typography style={{marginTop: "10px"}}>
              {textValue}
            </Typography>
          </>
        }

      </Grid>
      <Grid item xs={1} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        {controlButtons}
      </Grid>
    </Grid>
  </div>
  )
}

const HalfScreenModal = styled(Modal)`
  position: fixed;
  width: 50%;
  height: 50%;
  z-index: 1240;
  background: #2e6da4;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

// const HalfScreenModal = styled(Modal)`
//   position: fixed;
//   width: 50%;
//   height: 100vh;
//   z-index: 1240;
//   top: 0;
//   right: 0;
// `;
function Timelines({topicId}) {
    const dispatch = useDispatch();

    const { timelinesState } = useSelector((state) => state);

    const [timelines, setTimelines] = React.useState([]);
    const [showCreateTimelineModal, setShowCreateTimelineModal] = React.useState(false);
    const renderBackdrop = (props) => <Backdrop {...props} />;

    useEffect(() => {
      dispatch(getTimelines(topicId));
    }, []);

    useEffect(() => {setTimelines(timelinesState.timelines)}, [timelinesState.loading, timelinesState.timelineIsCreating]);

    const dispatchApproveAction = (userId, is_approved) => {
      toast.warning("Not implemented yet");
    }


    let content = null

    if (timelinesState.loading) {
      content = (
        <Typography variant={"h4"}>
          Loading timelines
        </Typography>
      )
    } else {
      content = (
        <>
          <TimelineDetails
            key={"new-timeline"}
            id={null}
            title={""}
            text={""}
            date={null}
            date_accuracy={4}
            views={0}
            created={null}
            updated={null}
            published={null}
            topicId={topicId}
          />
          {
            Array.isArray(timelines) && timelines.length? <></>: <div style={{
              display: "flex",
              justifyContent: "center"
            }}><Typography variant={"h4"}>
              No timelines
            </Typography></div>
          }
          {timelines.map((timeline_details) => (
            <TimelineDetails
              key={timeline_details.id}
              id={timeline_details.id}
              title={timeline_details.title}
              text={timeline_details.text}
              date={timeline_details.date}
              date_accuracy={timeline_details.date_accuracy}
              views={timeline_details.views}
              created={timeline_details.created}
              updated={timeline_details.updated}
              published={timeline_details.published}
              topicId={topicId}
            />
          ))}
        </>
      )
    }

    return (
        <Paper style={{
          padding: "15px",
          margin: "5px"
        }}>
          <IconButton aria-label="fingerprint" color="success" style={{position: "fixed", right: "15px", bottom: "15px"}}>
            {/*<ModalExample />*/}
            <AddCircleOutlined onClick={() => dispatch(openForm(<TimelineDetails
              key={"new-timeline"}
              id={null}
              title={""}
              text={""}
              date={null}
              date_accuracy={4}
              views={0}
              created={null}
              updated={null}
              published={null}
              topicId={topicId}
            />))}/>
          </IconButton>
          {content}
        </Paper>
    );
}

Timelines.propTypes = {
  topicId: PropTypes.string.isRequired
};

export default Timelines;
