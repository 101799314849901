import React from "react";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from "@mui/material/Divider";


const RowInformation = ({type, value}) => (
  <div style={{display: "flex", justifyContent: "space-between"}}>
    <Typography>
      {type}
    </Typography>
    <Typography>
      {value ? <>{value}</> : <>-</>}
    </Typography>
  </div>
)

function TopicInformation({title, image_url, description}) {
    return (
        <Card style={{margin: "5px"}}>
          <CardMedia
            image={image_url}
            style={{
              height: 400
            }}
            title={title}
          />
            <CardContent>
              <Typography>
                {title}
              </Typography>
              <Divider/>
              <Typography>{description}</Typography>
              <Divider/>
            </CardContent>
        </Card>
    );
}

TopicInformation.propTypes = {
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    mobilePhone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired
};

export default TopicInformation;
