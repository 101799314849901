import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Avatar, CardHeader, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {deleteTopic, getTimelineTopics} from "../modules/topics";
import { AddCircleOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CreateTopic from "./topics/createTopic";
import {openForm} from "../modules/form";
import Button from "@mui/material/Button";
import {LinkNoStyles} from "./elements/text";

function TopicRow({topic}) {
  const dispatch = useDispatch();

  return (
    <TableRow key={topic.id}>
      <TableCell style={{padding: 0}} component={LinkNoStyles} to={`/topics/${topic.id}/`}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="user"
              src={topic.image_url}
              sx={{ width: "64px", height: "64px" }}
            >
              {topic.title}
            </Avatar>
          }
          title={topic.title}
          subheader={<>{topic.created}</>}
        />
      </TableCell>
      <TableCell component={LinkNoStyles} to={`/topics/${topic.id}/`}>
        {
          topic.created_by_user?
            <Typography component={LinkNoStyles} to={`/users/${topic.created_by_user.id}/`}>
              {topic.created_by_user.full_name}
            </Typography> : <Typography>-</Typography>
        }
      </TableCell>
      <TableCell>
        <Button fullWidth={true} variant="outlined" color="error" onClick={() => dispatch(deleteTopic(topic.id))} style={{zIndex: 100}}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

const TopicsList = () => {

  const dispatch = useDispatch();
  const { topicsState } = useSelector((state) => state);
  const [ topics, setTopics ] = useState([]);
  const [ searchInput, setSearchInput ] = useState("")
  const [filteredResults, setFilteredResults] = useState([]);

  const search = (searchValue) => {
    setSearchInput(searchValue)
  }

  useEffect(() => {
    // Dispatch an action to fetch the user data
    dispatch(getTimelineTopics());
  }, [dispatch]);

  useEffect(() => {
    if (topicsState.topics.length === 0) return;
    setTopics(topicsState.topics)
    if (searchInput) {
      const filteredData = topicsState.topics.filter((topic) => {
        return topic.title.toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(topicsState.topics)
    }
  }, [topicsState.loading])


  useEffect(() => {
    if (searchInput) {
      const filteredData = topics.filter((topic) => {
        return topic.title.toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(topics)
    }
  }, [searchInput])

  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <TextField
            fullWidth
            label="Search by topic name"
            id="fullWidth"
            variant="standard"
            value={searchInput}
            onChange={(e) => search(e.target.value)}
          />
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created by</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredResults.map((topic) => (
              <TopicRow key={topic.id} topic={topic} />
            ))}
              </TableBody>
          </Table>
        </Paper>
      </Grid>
      <IconButton aria-label="fingerprint" color="success" style={{position: "fixed", right: "15px", bottom: "15px"}}>
        <AddCircleOutlined onClick={() => dispatch(openForm({
          title: "Create Topic",
          description: "Topic is event or activity that you want to share",
          content: <CreateTopic />}))}/>
      </IconButton>
    </>
  );
}
export default TopicsList;
