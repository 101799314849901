import axios from "axios";
import UserService from "./UserService";
import { toast } from 'react-toastify';

const HttpMethods = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH'

};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  });
  _axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log(err.response)
      if (err.response.status >= 399) {
        if (err.response.data) {
          if (err.response.data.detail) {
            toast.error(err.response.data.detail);
            return Promise.reject(err);
          } else {
            toast.error(err.response.data);
            return Promise.reject(err);
          }
        }
        toast.error("Unhandled backend error.");
        return Promise.reject(err);
      }

      return Promise.reject(err);
    }
  );
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
