import {getHostUrl, MAIN_API_SERVICE } from "./environments";
import {FAILURE_SUFFIX, REQUEST_SUFFIX, SUCCESS_SUFFIX } from "./types";
import HttpService from "../services/HttpService";

const GET_TOPICS = 'GET_TOPICS';
const CREATE_TOPIC = 'CREATE_TOPIC';
const DELETE_TOPIC = 'DELETE_TOPIC';

const initialState = {
  topics: [],
  loading: false,
  error: null,
  topicIsCreating: false
};
const topicsReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case DELETE_TOPIC + REQUEST_SUFFIX:
    case CREATE_TOPIC + REQUEST_SUFFIX:
      return {
        ...state,
        topicIsCreating: true,
        loading: true,
      }

    case CREATE_TOPIC + SUCCESS_SUFFIX:
      return {
        ...state,
        topics: [...state.topics, payload.data],
        topicIsCreating: false,
        loading: false
      }

    case CREATE_TOPIC + FAILURE_SUFFIX:
      return {
        ...state,
        topicIsCreating: false
      }

    case DELETE_TOPIC + SUCCESS_SUFFIX:
      return {
        ...state,
        topicIsCreating: false,
        loading: false,
        topics: state.topics.filter(topic => topic.id !== payload.data.id)
      }

    case GET_TOPICS + REQUEST_SUFFIX:
      return {
        ...state,
        loading: true,
      };

    case GET_TOPICS + SUCCESS_SUFFIX:
      console.log(payload.data);
      return {
        ...state,
        topics: payload.data,
        loading: false,
      };

    default:
      return state;
  }
};

export default topicsReducer;

export const getTimelineTopics = () => {
  return {
    types: [GET_TOPICS + REQUEST_SUFFIX, GET_TOPICS + SUCCESS_SUFFIX, GET_TOPICS + FAILURE_SUFFIX],
      payload: {
    request: {
      url: `${getHostUrl(MAIN_API_SERVICE)}/topics?skip=0&limit=1000&expand_user=true`,
    },
  },
  }
};

export const deleteTopic = (topicId) => {
  return {
    types: [DELETE_TOPIC + REQUEST_SUFFIX, DELETE_TOPIC + SUCCESS_SUFFIX, DELETE_TOPIC + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(MAIN_API_SERVICE)}/topics/${topicId}`,
        method: HttpService.HttpMethods.DELETE
      },
    },
  }
}
export const createTopic = ({title, description, image_url}) => {


  return {
    types: [CREATE_TOPIC + REQUEST_SUFFIX, CREATE_TOPIC + SUCCESS_SUFFIX, CREATE_TOPIC + FAILURE_SUFFIX],
    payload: {
      request: {
        url: `${getHostUrl(MAIN_API_SERVICE)}/topics`,
        method: HttpService.HttpMethods.POST,
        data: {
          title: title,
          description: description,
          image_url: image_url
        }
      },
    },
  }
};
