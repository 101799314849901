import { combineReducers } from "redux";
import users from "./users";
import walletsState from "./wallet"
import articles from "./articles";
import experiencesState from "./experience";
import topicsState from "./topics";
import formState from "./form"
import imageState from "./images"
import timelinesState from "./timelines"
import aggregatedNews from "./aggregatedNews"

export default combineReducers({
  users, walletsState, articles, experiencesState, topicsState, formState, imageState, timelinesState, aggregatedNews
});
