import {getHostUrl, MAIN_API_SERVICE, USER_SERVICE} from "./environments";
import {FAILURE_SUFFIX, REQUEST_SUFFIX, SUCCESS_SUFFIX } from "./types";
import HttpService from "../services/HttpService";

const GET_EXPERIENCE = 'GET_EXPERIENCE';

const initialState = {
  experiences: [],
  loading: false,
  error: null
};

const experienceReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case GET_EXPERIENCE + REQUEST_SUFFIX:
      return {
        ...state,
        loading: true,
      };

    case GET_EXPERIENCE + SUCCESS_SUFFIX:
      return {
        ...state,
        experiences: payload.data,
        loading: false,
      };

    default:
      return state;
  }
};

export default experienceReducer;

export const getUserExperiences = (userId) => {
  return {
    types: [GET_EXPERIENCE + REQUEST_SUFFIX, GET_EXPERIENCE + SUCCESS_SUFFIX, GET_EXPERIENCE + FAILURE_SUFFIX],
      payload: {
    request: {
      url: `${getHostUrl(MAIN_API_SERVICE)}/users/${userId}/experience`,
    },
  },
  }
};
